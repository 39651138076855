<template>
  <div class="home-main">
    <create-popup></create-popup>
    <blank-canvas></blank-canvas>
    <div class="logo">
      <span class="material-icons" >whatshot</span>
      <span style="font-weight: 500">Fire</span>
      <span>Plan</span>
    </div>
    <div class="home-content">
      <div class="pbtn2" @click="showCreateProject()">Create Project</div>
      <div class="pbtn" @click="openFileInput()">Open Existing</div>
      <div id="dropRegion" @click="openFileInput()">
        <span style="font-size: 64px; margin-bottom: 16px" class="material-icons">insert_drive_file</span>
        <span>Drop Here</span>
      </div>
    </div>
  </div>
</template>

<script>
import BlankCanvas from '../components/BlankCanvas.vue';
import CreatePopup from '../components/CreatePopup.vue';

export default {
  name: 'Home',
  components: {
    CreatePopup,
    BlankCanvas
  },
  methods: {
    openFileInput(){
      let fileInput = document.getElementById('fileInput');
      fileInput.value = null;
      let listener = ()=>{
        fileInput.removeEventListener('change', listener);
        let file = fileInput.files[0];
        if (!file) {
          return;
        }
        var reader = new FileReader();
        reader.onload = (e)=>{
          var contents = e.target.result;
          let mJson = JSON.parse(contents);
          this.$router.push({
            name: 'Editor',
            params: {
              iproject: mJson
            }
          })
        };
        reader.readAsText(file);
      }
      fileInput.addEventListener('change', listener);
      fileInput.click();
    },
    showCreateProject(){
      this.$root.$emit('show-project-popup')
    }
  },
  mounted() {
    let dropRegion = document.getElementById('dropRegion');
    dropRegion.addEventListener('drop', (event)=>{
      event.preventDefault()
      dropRegion.classList.remove('active')
      let files = event.dataTransfer.files;
      if(files.length == 0) return;
      let file = files[0];
      if(file.type == 'application/json' && file.name.toLowerCase().endsWith('.fireplan.json')){
        var reader = new FileReader();
        reader.onload = (e)=>{
          var contents = e.target.result;
          let mJson = JSON.parse(contents);
          this.$router.push({
            name: 'Editor',
            params: {
              iproject: mJson
            }
          })
        };
        reader.readAsText(file);
      }
    })

    dropRegion.addEventListener('dragenter', (event)=>{
      event.preventDefault()
      dropRegion.classList.add('active')
    })

    dropRegion.addEventListener('dragover', (event)=>{
      event.preventDefault()
    })

    dropRegion.addEventListener('dragleave', (event)=>{
      event.preventDefault()
      dropRegion.classList.remove('active')
    })

    this.$root.$on('create-project', (project)=>{
      this.$router.push({
        name: 'Editor',
        params: {
          iproject: project
        }
      })
    })
  },
}
</script>

<style>
  .home-main{
    width: 100%;
    height: 100%;
    background: #FFF;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .home-content{
    width: 90%;
    max-width: 512px;
    background: var(--color-primary);
    border-radius: 8px;
    margin-top: 32px;
    padding: 16px;
    position: relative;
    z-index: 1;
  }

  .logo{
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
    position: relative;
    z-index: 1;
  }

  .logo .material-icons{
    color: var(--color-accent);
    font-size: 48px;
    margin-right: 16px;
  }

  .logo span{
    font-size: 32px;
  }

  .pbtn{
    width: calc(100% - 16px);
    padding: 8px;
    color: var(--color-accent);
    border: solid 2px var(--color-accent);
    margin: 8px;
    border-radius: 4px;
    transition: all 0.25s;
    cursor: pointer;
    text-align: center;
    user-select: none;
  }

  .pbtn:hover{
    background: var(--color-accent);
    color: #fff;
  }

  .pbtn:active{
    background: transparent;
  }

  .pbtn2{
    width: calc(100% - 16px);
    padding: 8px;
    color: #FFF;
    background: var(--color-accent);
    margin: 8px;
    border-radius: 4px;
    transition: all 0.25s;
    cursor: pointer;
    text-align: center;
    user-select: none;
  }

  .pbtn2:hover{
    background: #db6a00;
  }

  .pbtn2:active{
    background: #c75a00;
  }

  #dropRegion{
    width: calc(100% - 16px);
    height: 256px;
    border: dashed 2px rgba(255,255,255,0.25);
    border-radius: 8px;
    margin: 8px;
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: rgba(255,255,255,0.25);
    font-size: 24px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.25s;
    user-select: none;
  }

  #dropRegion *{
    display: flex;
    transition: transform 0.25s;
  }

  #dropRegion:hover{
    color: rgba(255,255,255,0.5);
    border: dashed 2px rgba(255,255,255,0.5);
  }

  #dropRegion.active{
    color: #FFF;
    border: dashed 2px #FFF;
  }

  #dropRegion.active * {
    transform: scale(1.25);
  }
</style>
