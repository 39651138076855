<template>
  <transition name="slide-fade">
    <div v-if="visible" class="popup-holder">
      <div class="popup-above">
        <span v-if="!isUpdate">Create Project</span>
        <span v-if="isUpdate">Edit Project</span>
        <div class="material-icons close-btn" @click="closeClick()">close</div>
      </div>
      <div class="popup-window">
        <span class="popup-label">Project Name</span>
        <input class="popup-input" v-model="project.name" placeholder="My New Plan" />
        <span class="popup-label">Author</span>
        <input class="popup-input" v-model="project.author" placeholder="Aditya Kharote" />
        <div class="pbtn2" v-if="project.name.trim()!='' && project.author.trim()!='' && !isUpdate" 
          style="margin-top: 24px; font-weight: 500" @click="doneClick()">Done</div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'ProjectPopup',
  props: {
    msg: String
  },
  methods: {
    doneClick(){
      this.visible = false;
      this.$root.$emit('create-project', {
        name: this.project.name,
        author: this.project.author,
        plans: [
          {
            name: "Plan 1",
            fire_escape_room: null,
            rooms: [],
            paths: {},
            path_info: {},
            firstSelection: null,
            secondSelection: null
          }
        ]
      });
    },
    closeClick(){
      this.visible = false;
    }
  },
  data() {
    return {
      project: {
        name: "",
        author: ""
      },
      visible: false,
      isUpdate: false
    }
  },
  mounted(){
    this.$root.$on('show-project-popup', project=>{
      this.isUpdate = false;
      if(project != null){
        this.project = project;
        this.isUpdate = true;
      }
      this.visible = true;
    })
  }
}
</script>

<style>
.popup-holder{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.9);
  color: #FFF;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 5;
}

.popup-above{
  display: flex;
  font-size: 24px;
  width: 90%;
  max-width: 480px;
  margin-bottom: 16px;
  align-items: center;
}

.popup-window{
  width: 90%;
  max-width: 480px;
  padding: 24px 32px;
  background: var(--color-primary);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  text-align: left;
}

.popup-label{
  text-align: left;
  font-size: 14px;
  font-weight: 500;
  margin-top: 4px;
  margin-bottom: 4px;
}

.popup-input{
  background: rgba(142, 202, 230, 0.25);
  border: none;
  outline: none;
  height: 48px;
  margin: 4px 0px;
  color: #FFF;
  border-radius: 4px;
  padding: 0px 12px;
  transition: all 0.1s;
  width: 100%;
  display: block;
}

.popup-input:focus{
  outline: solid 2px var(--color-accent);
}

.popup-input::placeholder{
  color: var(--color-secondary);
}

.close-btn{
  padding: 16px;
  margin-left: auto;
  border-radius: 50%;
  transition: background 0.25s;
  cursor: pointer;
  user-select: none;
}

.close-btn:hover{
  background: rgba(255, 255, 255, 0.25);
}

.close-btn:active{
  background: rgba(255, 255, 255, 0.15);
}

.slide-fade-enter-active {
  transition: all .25s ease;
}
.slide-fade-leave-active {
  transition: all .25s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to{
  transform: translateY(10px);
  opacity: 0;
}
</style>
