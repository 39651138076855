<template>
  <div class="main-canvas-outer">
    <canvas id="blank-canvas" width="500" height="500"></canvas>
  </div>
</template>

<script>
/*eslint no-unused-vars: "off"*/
/*eslint no-constant-condition: "off"*/

import Stack from '../ds/stack';
import uniqueId from '../utils';

let canvas;
let ctx;

const squareSize = 28;

export default {
  name: 'PlanCanvas',
  components: {},
  props: ['plan','undoStack'],
  methods: {
    initialize(){
      window.addEventListener('resize', this.onResize);
      this.onResize();
    },
    onResize(){
      canvas.width = canvas.clientWidth;
      canvas.height = canvas.clientHeight;
    },
    drawGrid() {
      let width = canvas.width;
      let height = canvas.height;
      let scaledSquareSize = squareSize;
      ctx.strokeStyle = 'lightgrey';
      ctx.lineWidth = 1;
      ctx.beginPath()
      let p = [0, 0]
      for (let x = p[0]; x <= width; x += scaledSquareSize) {
        ctx.moveTo(x, 0)
        ctx.lineTo(x, height)
      }
      for (let y = p[1]; y <= height; y += scaledSquareSize) {
          ctx.moveTo(0, y)
          ctx.lineTo(width, y)
      }

      for (let x = p[0] - scaledSquareSize; x >=0; x -= scaledSquareSize) {
          ctx.moveTo(x, 0)
          ctx.lineTo(x, height)
      }
      for (let y = p[1] - scaledSquareSize; y >= 0; y -= scaledSquareSize) {
          ctx.moveTo(0, y)
          ctx.lineTo(width, y)
      }
      ctx.stroke()
    },

    redraw(){
      ctx.clearRect(0, 0, window.innerWidth, window.innerHeight)
      this.drawGrid();
      requestAnimationFrame(this.redraw)
    }
  },
  mounted () {
    canvas = document.getElementById('blank-canvas');
    ctx = canvas.getContext('2d')
    this.initialize()
    this.redraw()
  },
}

</script>

<style>
  #blank-canvas{
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 0;
  }
</style>