let uniqueId = function(){
    return crypto.randomUUID();
    /*let choices = "1234567890";
    choices += "abcdefghijklmnopqrstuvwxyz";
    choices += "abcdefghijklmnopqrstuvwxyz".toUpperCase();
    let output = "";
    for(let i = 0; i < 10; i++){
        output += choices[Math.floor(Math.random() * choices.length)];
    }
    return output;*/
}

export default uniqueId;