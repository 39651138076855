class Stack{
    constructor(){
        this.items = [];
    }

    getSize(){
        return this.items.length;
    }

    push(item){
        this.items.push(item);
    }

    pop(){
        return this.items.pop();
    }

    peek(){
        if(this.items.length == 0) return undefined;
        return this.items[this.items.length-1]
    }

    isEmpty(){
        return this.items.length == 0;
    }

    clear(){
        this.items.splice(0, this.items.length)
    }
}

export default Stack;